.custom-product-information-container {
  display: flex;
  align-items: center;

  gap: 1rem;

  width: 100%;
  justify-content: end;
}

.custom-product-information {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;

    flex-wrap: nowrap;

    border: 1px solid #999;
    border-radius: 2rem;

    padding: 0.2rem 1rem;
    cursor: pointer;

    position: relative;
  }

  &__text {
    margin: 0 0.5rem;
    font-size: 1rem;
  }

  &__icon,
  &__hint {
    min-width: 16px;
    height: auto;
  }

  &__hint {
    position: absolute;

    top: 0;
    right: 0;

    transform: translate(4px, -6px);

    background: white;
  }
}
